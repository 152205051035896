import Rollbar from 'rollbar';

/*
https://docs.rollbar.com/docs/javascript#ignoring-items
https://docs.rollbar.com/docs/browser-js
https://docs.rollbar.com/docs/rollbarjs-configuration-reference
*/

const rollbar = new Rollbar({
  accessToken: __ROLLBAR_ACCESS_TOKEN__,
  autoInstrument: {
    log: false,
  },
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: __DEVELOPMENT__ ? 'ignored' : 'www',
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: __GIT_REVISION__,
        guess_uncaught_frames: true,
      },
    },
  },
  checkIgnore(_isUncaught, _args, payload) {
    const { environment } = payload;
    return (
      environment === 'ignored'
      || _isUncaught === true
      || payload.level === 'debug'
    );
  },
});

export default rollbar;

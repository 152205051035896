import { datadogRum } from '@datadog/browser-rum';

const ddConfig = {
  applicationId: __DATADOG_APPLICATION_ID__,
  clientToken: __DATADOG_CLIENT_TOKEN__,
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.eu',
  service: 'www',
  env: 'www',
  // Specify a version number to identify the deployed version of your application in Datadog
  version: __GIT_BRANCH__ + '-' + __GIT_ABBREVIATED_SHA__,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
};

datadogRum.init(ddConfig);

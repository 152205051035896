import { StrictMode, useState, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { lookup } from '@/components/forms/l10n';
import IntlTelInput from 'intl-tel-input/reactWithUtils';
import { missing_hubspot_cookie, not_company_email, track_form_submission, redirect_after, invalid_phone_number } from './utils';

function ThankyouDefault() {
  const url = new URL(document.URL);
  const page_lang = url.pathname.split('/')[1];
  return (
    <h2 className="form__title title-400 title-medium">{lookup(page_lang, 'thanks_for_contacting')}</h2>
  );
}

function ThankyouMessage({msg}){
  const html = { __html: msg};

  return(
    <div className='thankyou-message' dangerouslySetInnerHTML={html}></div>
  );
}

function ThankyouRedirect({ url }) {
  const purl = new URL(document.URL);
  const page_lang = purl.pathname.split('/')[1];
  const msg = '<p><a href="' + url + '">' + lookup(page_lang, 'no_redirect') + '</a></p>';
  const html = { __html: msg };

  return (
    <div className='thankyou-redirect' dangerouslySetInnerHTML={html}></div>
  );
}

const propTypes = {
  blockId: PropTypes.string,
  title: PropTypes.string,
  button: PropTypes.string,
}

function TrialForm({ blockId, title, button, backgroundColour }) {
  const url = new URL(document.URL);
  const page_lang = url.pathname.split('/')[1];
  const [sent, setSent] = useState(false);
  const [status, setStatus] = useState(lookup(page_lang, 'please_wait'));
  const [showStatus, setShowStatus] = useState(false);
  const [help, setHelp] = useState(lookup(page_lang, 'help'));
  const [showHelp, setShowHelp] = useState(false);
  const [showThankyou, setShowThankyou] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneErrorCode, setPhoneErrorCode] = useState(0);

  const thankyouRef = useRef(null);

  const statusClass = classNames(
    'form-status',
    'form--' + backgroundColour,
    {
      'hidden': !showStatus
    });

  const helpClass = classNames(
    'form-help',
    'form--' + backgroundColour,
    {
      'hidden': !showHelp
    });

  const formClass = classNames(
    'form',
    'form--' + backgroundColour,
    {
      'hidden': sent
    });

  const thankyouClass = classNames(
    'form-thankyou',
    'form--' + backgroundColour,
    {
      'hidden': !showThankyou
    });

  async function send(formData) {
    const jsonObject = Object.fromEntries(formData);
    const jsonString = JSON.stringify(jsonObject);
    const csrftoken = Cookies.get('csrftoken');
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-CSRFToken', csrftoken);
    try{
      const response = await fetch('/api/forms/trial/', {
        method: 'POST',
        body: jsonString,
        headers: headers
      });
      const data = await response.json();
      if (response.status === 200) {
        track_form_submission(window, document.title, document.URL);

        const root = createRoot(thankyouRef.current);
        if ('redirect' in data && data['redirect'] !== '' && data['redirect'] !== undefined) {
          const url = data['redirect'];
          root.render(<ThankyouRedirect url={url} />);
          redirect_after(url);
        }
        else if ('thankyou' in data && data['thankyou'] !== '' && data['thankyou'] !== undefined){
          const msg = data['thankyou'];
          root.render(<ThankyouMessage msg={msg} />);
        }
        else{
          root.render(<ThankyouDefault />);
        }
        setShowStatus(false);
        setShowThankyou(true);
      }
      else {
        if (missing_hubspot_cookie(data)){
          setStatus(lookup(page_lang, 'hs_cookies'));
        }
        else if (not_company_email(data)){
          setHelp(lookup(page_lang, 'use_company_email'));
          setShowHelp(true);
          setSent(false);
          setShowStatus(false);
        }
        else if (invalid_phone_number(data)) {
          setHelp(lookup(page_lang, 'invalid_phone'));
          setShowHelp(true);
          setSent(false);
          setShowStatus(false);
        }
        else{
          setStatus(lookup(page_lang, 'error'));
        }
      }
    }
    catch(error){
      console.error(`Error sending data: ${error.message}`);
      setStatus(lookup(page_lang, 'error'));
    }
  }

  function submit(e) {
    e.preventDefault();
    if (!phoneIsValid){
      console.log('Phone number error: ' + phoneErrorCode);
      setHelp(lookup(page_lang, 'invalid_phone'));
      setShowHelp(true);
      setSent(false);
      setShowStatus(false);
      return;
    }

    setSent(true);
    setShowStatus(true);
    setShowHelp(false);
    const form = document.getElementById('trial-form');
    const formData = new FormData(form);
    formData.set('page_name', document.title);
    formData.set('page_uri', document.URL);
    formData.set('phone_number', phoneNumber);
    send(formData);
  }

  function geo_ip_lookup(success, failure){
    const country_code = Cookies.get('country_code');
    if (country_code){
      return success(country_code);
    }
    fetch('https://ipapi.co/json')
      .then(function (res) { return res.json(); })
      .then(function (data) {
        Cookies.set('country_code', data.country_code);
        success(data.country_code);
      })
      .catch(function () { failure(); });
  }

  return (
    <>
      <div className={statusClass}>
        <h2 className="form__title title-400 title-medium">{status}</h2>
      </div>
      <div className={helpClass}>
        <h2 className="form__title title-400 title-medium">{help}</h2>
      </div>
      <div className={thankyouClass} ref={thankyouRef}></div>
      <form className={formClass} onSubmit={submit} id='trial-form'>
        <div className="form__row">
          <div className="form__col-50">
            <div className="form-floating">
              {/* eslint-disable-next-line @stylistic/js/max-len */}
              <input name='first_name' type="text" className="form-control" id="trial-form-first-name" placeholder="first name" autoComplete="given-name" required />
              <label htmlFor="trial-form-first-name">{lookup(page_lang, 'first_name')}</label>
            </div>
          </div>
          <div className="form__col-50">
            <div className="form-floating">
              {/* eslint-disable-next-line @stylistic/js/max-len */}
              <input name='last_name' type="text" className="form-control" id="trial-form-last-name" placeholder="last name" autoComplete="family-name" required />
              <label htmlFor="trial-form-last-name">{lookup(page_lang, 'last_name')}</label>
            </div>
          </div>
        </div>
        <div className="form-floating">
          <input name='email' type="email" className="form-control" id="trial-form-email" placeholder="name@example.com" autoComplete="email" required />
          <label htmlFor="trial-form-email">{lookup(page_lang, 'company_email')}</label>
        </div>
        <div className="form-floating">

          <IntlTelInput
            onChangeNumber={setPhoneNumber}
            onChangeValidity={setPhoneIsValid}
            onChangeErrorCode={setPhoneErrorCode}
            inputProps={{
              id: 'trial-form-phone',
              name: 'phone',
              className: 'form-control',
              required: true
            }}
            initOptions={{
              initialCountry: 'auto',
              geoIpLookup: geo_ip_lookup,
            }}
          />

        </div>
        <div className="form-floating">
          {/* eslint-disable-next-line @stylistic/js/max-len */}
          <input name='company' type="text" className="form-control" id="trial-form-company-name" placeholder="company name" autoComplete="organization" />
          <label htmlFor="trial-form-company-name">{lookup(page_lang, 'company_name')}</label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" value="" id="trial-form-check" required />
          <label className="form-check-label" htmlFor="trial-form-check" dangerouslySetInnerHTML={{ __html: lookup(page_lang, 'agree_privacy') }}></label>
        </div>
        <button className="form__button form__submit">
          {/* eslint-disable-next-line @stylistic/js/max-len */}
          <svg width="24" height="24" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg"><path d="M17.92 11.6199C17.8724 11.4972 17.801 11.385 17.71 11.2899L12.71 6.28994C12.6168 6.1967 12.5061 6.12274 12.3842 6.07228C12.2624 6.02182 12.1319 5.99585 12 5.99585C11.7337 5.99585 11.4783 6.10164 11.29 6.28994C11.1968 6.38318 11.1228 6.49387 11.0723 6.61569C11.0219 6.73751 10.9959 6.86808 10.9959 6.99994C10.9959 7.26624 11.1017 7.52164 11.29 7.70994L14.59 10.9999H7C6.73478 10.9999 6.48043 11.1053 6.29289 11.2928C6.10536 11.4804 6 11.7347 6 11.9999C6 12.2652 6.10536 12.5195 6.29289 12.707C6.48043 12.8946 6.73478 12.9999 7 12.9999H14.59L11.29 16.2899C11.1963 16.3829 11.1219 16.4935 11.0711 16.6154C11.0203 16.7372 10.9942 16.8679 10.9942 16.9999C10.9942 17.132 11.0203 17.2627 11.0711 17.3845C11.1219 17.5064 11.1963 17.617 11.29 17.7099C11.383 17.8037 11.4936 17.8781 11.6154 17.9288C11.7373 17.9796 11.868 18.0057 12 18.0057C12.132 18.0057 12.2627 17.9796 12.3846 17.9288C12.5064 17.8781 12.617 17.8037 12.71 17.7099L17.71 12.7099C17.801 12.6148 17.8724 12.5027 17.92 12.3799C18.02 12.1365 18.02 11.8634 17.92 11.6199Z" /></svg>
          <span>{button}</span>
        </button>
        <input name='block_id' type='hidden' value={blockId} />
      </form>
    </>
  );
}

TrialForm.propTypes = propTypes;

export default function createTrialForms() {
  const encloser_matches = document.getElementsByClassName('trial-form-encloser');

  for (const encloser of encloser_matches){
    const parent_matches = encloser.getElementsByClassName('trial-form-parent');
    if (parent_matches.length === 0){
      continue;
    }
    const parent = parent_matches[0];
    const form_root = createRoot(parent);
    form_root.render(
      <StrictMode>
        <TrialForm
          blockId={encloser.dataset.blockId}
          title={encloser.dataset.formTitle}
          button={encloser.dataset.formButton}
          backgroundColour={encloser.dataset.formBackgroundColour}
        />
      </StrictMode>
    );
  }
}

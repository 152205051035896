import { StrictMode, useState } from 'react';
import { createRoot } from 'react-dom/client';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import { missing_hubspot_cookie, track_form_submission } from '@/components/forms/utils';
import { lookup } from '@/components/forms/l10n';


function NewsletterForm() {
  const url = new URL(document.URL);
  const page_lang = url.pathname.split('/')[1];
  const [sent, setSent] = useState(false);
  const [status, setStatus] = useState(lookup(page_lang, 'please_wait'));
  const statusClass = classNames(
    'form--dark',
    'form-status',
    {
      'hidden': !sent
    });

  const formClass = classNames(
    'form',
    'form--dark',
    'footer__form',
    {
      'hidden': sent
    });

  async function send(formData) {
    const jsonObject = Object.fromEntries(formData);
    const jsonString = JSON.stringify(jsonObject);
    const csrftoken = Cookies.get('csrftoken');
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('X-CSRFToken', csrftoken);
    try{
      const response = await fetch('/api/forms/newsletter/', {
        method: 'POST',
        body: jsonString,
        headers: headers
      });
      if (response.status === 200) {
        track_form_submission(window, document.title, document.URL);

        setStatus(lookup(page_lang, 'subs_thanks'));
      }
      else {
        const data = await response.json();
        if (missing_hubspot_cookie(data)){
          setStatus(lookup(page_lang, 'hs_cookies'));
        }
        else{
          setStatus(lookup(page_lang, 'error'));
        }
      }
    }
    catch(error){
      console.error(`Error sending data: ${error.message}`);
      setStatus(lookup(page_lang, 'error'));
    }
  }

  function submit(e) {
    e.preventDefault();
    setSent(true);
    const form = document.getElementById('footer-newsletter-form');
    const formData = new FormData(form);
    formData.set('page_name', document.title);
    formData.set('page_uri', document.URL);
    send(formData);
  }
  return (
    <>
      <div className={statusClass}>
        <h2 className="form__title title-200 title-medium">{status}</h2>
      </div>
      <form className={formClass} onSubmit={submit} id='footer-newsletter-form'>
        <h2 className="form__title title-200 title-medium">{lookup(page_lang, 'subs_title')}</h2>
        <div className="form-floating">
          <input name='email' type="email" className="form-control" id="newsletter-form-email" placeholder="name@example.com" autoComplete="email" required />
          <label htmlFor="newsletter-form-email">{lookup(page_lang, 'email_address')}</label>
        </div>
        <div className="form-check">
          <input className="form-check-input" type="checkbox" value="" id="newsletter-form-check" required />
          <label className="form-check-label" htmlFor="newsletter-form-check" dangerouslySetInnerHTML={{ __html: lookup(page_lang, 'agree_privacy') }}></label>
        </div>
        <button className="footer__button">
          {/* eslint-disable-next-line @stylistic/js/max-len */}
          <span>{lookup(page_lang, 'send')}</span>
        </button>
      </form>
    </>
  );
}


export default function createFooterNewsletterForm() {
  const domNode = document.getElementById('footer-newsletter-form-parent');
  const root = createRoot(domNode);
  root.render(
    <StrictMode>
      <NewsletterForm />
    </StrictMode>
  );
}
